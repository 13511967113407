export default {
  container: {
    width: '100%',
    display: ['', '', 'flex'],
    flexWrap: 'wrap',
    padding: ['0rem', '', '2rem'],
  },

  contentRow: {
    padding: '0rem 1rem',
    width: ['100%', '', '60%'],
    position: 'relative',
  },

  title: {
    fontSize: ['2rem', '2.75rem', '3rem'],
    marginBottom: '0.5rem',
    color: 'primary',
    width: '100%',
    textTransform: 'capitalize',
    borderBottom: '1px solid lightgrey',
    paddingBottom: '1rem',
    marginBottom: '1rem',
  },
  subtitle: {
    marginBottom: '1rem',
    color: 'secondary',
    textTransform: 'capitalize',
    fontSize: ['1.25rem', '1.5rem', '1.75rem'],

  },
  text: {},

  timeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    borderBottom: '1px solid',
    opacity: '0.6',
    padding: '0.5rem 0rem 0rem',
    margin: 'auto 0rem 2rem',
    width: '100%',
  },

  readingTime: {
    fontSize: '0.8em',
    margin: '0rem 1rem 0rem 0rem',
    padding: '0.5rem 0rem',
  },

  createdAt: {
    fontSize: '0.8em',
    margin: '0rem 1rem 0rem 0rem',
    padding: '0.5rem 0rem',
  },

  imageContainer: {
    marginBottom: ['2.5rem', '', '', '1rem'],
    width: ['100%', '', '40%'],
    padding: '0rem 1rem',
  },

  image: {
    width: '100%',
  },

  bodyContainer: {
    h1: {
      fontSize: '2rem',
      margin: '0rem 0rem 1rem',
    },
    h2: {
      fontSize: '1.75rem',
      margin: '0rem 0rem 1rem',
    },
    h3: {
      fontSize: '1.5rem',
      margin: '0rem 0rem 1rem',
    },
    h4: {
      fontSize: '1.25rem',
      margin: '0rem 0rem 1rem',
    },
    h5: {
      fontSize: '1rem',
      margin: '0rem 0rem 1rem',
    },
    h6: {
      fontSize: '0.8rem',
      margin: '0rem 0rem 1rem',
    },
    p: {
      lineHeight: '1.5',
      margin: '0rem 0rem 1rem',
      fontWeight: 'normal',
    },
    ul: {
      margin: '0rem 0rem 1rem',
      listStylePosition: 'inside',
    },
    ol: {
      margin: '0rem 0rem 1rem',
      listStylePosition: 'inside',
    },
    li: {
      lineHeight: '1.5',
      marginBottom: '0.5rem',
      fontWeight: 'normal',
    },
    a: {
      color:'text',
      fontWeight:'bold',
    }
  },

  ctaButton: {
    margin: ['1rem 0rem', '', '', '2rem 2rem'],
    position: ['absolute'],
    top: '0rem',
    right: '1rem',
    backgroundColor: 'primary',
    color: 'white',
    padding: '0.75rem',
    borderRadius: '10px',
    width: '25%',
    fontSize: '0.8rem',
    textAlign: 'center',
    maxWidth: '200px',
  },

  // ? ===============================
  // ? ======= Other Articles ========
  // ? ===============================

  otherArticlesContainer: {
    width: ['100%', '', '280px'],
    position: ['', '', 'fixed'],
    left: ['', '', '0rem'],
    bottom: '42px',
    backgroundColor: ['', '', '#fff4f4'],
    boxShadow: ['', '', '2px 0px 7px #2d2d2d'],
    padding: ['', '', '0.5rem'],
    background: ['', '', 'primary'],
    borderRadius: ['', '', '0px 20px 0px 0px'],
    zIndex: '200',
    ':hover .otherArticles': {
      height: ['', '', '30vh'],
    },
    marginBottom: ['2rem', '', '0rem'],
  },
  otherArticleHeading: {
    color: 'black',
    fontSize: ['1rem', '', '1rem'],
    marginBottom: ['1rem', '', '0rem'],
    textTransform: 'capitalize',
    // marginBottom: '0.5rem',
    '::after': {
      content: ["''", "''", "'▼'"],
      color: 'primary',
      marginLeft: '0.5rem',
    },
  },
  otherArticles: {
    maxHeight: ['', '', '30vh'],
    overflow: 'scroll',
    transition: 'all ease-in-out 0.6s',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    height: ['', '', '0vh'],
    a: {
      textDecoration: 'none',
    },
  },
  otherArticle: {
    display: 'flex',
    alignItems: 'stretch',
    width: '100%',
    margin: '0.5rem 0rem',
    ':hover': {
      h5: {
        textDecoration: 'underline',
      },
    },
  },
  otherArticleImage: {
    width: '60px',
    height: '60px',
    objectFit: 'cover',
  },
  otherArticleContent: {
    padding: '0rem 0.5rem',
    width: '70%',
    display: 'flex',
    justifyContent: ['', '', 'center'],
    flexDirection: 'column',
  },
  otherArticleTitle: {
    fontSize: '1rem',
    lineHeight: '1.25',
    color: 'primary',
    textTransform: 'capitalize',
  },
  otherArticleSubtitle: {
    fontSize: '0.8rem',
    textTransform: 'capitalize',
    color: ['', '', 'dark'],
  },
  otherArticleDate: {
    fontSize: '0.7rem',
    opacity: '0.5',
    display: 'none',
  },
}
