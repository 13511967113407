export default {
  container: {
    margin: "0 auto",
    // paddingTop: ["100px", "", "", "200px"],
    width: "100%",
  },

  contentRow: {
    display: ["", "", "", "flex"],
  },

  headingImageContainer: {
    position: "relative",
  },

  heading: {
    // padding: ["", "", "", "1.5rem 5rem", "1.5rem 6rem"],
    // position: ["", "", "", "absolute"],
    bottom: ["", "", "", "10rem"],
    zIndex: "10",
  },

  title: {
    fontSize: ["2.5rem", "2.75rem", "3rem"],
    marginBottom: "1rem",
    lineHeight: "1.5",
    color: "#00557c",
    width: ["", "", "", "85%"],
  },
  subtitle: {
    marginBottom: "1rem !important",
    color: "#ca3625",
  },
  text: {},

  column1: {
    width: ["100%", "", "", "75%"],
    position: ["", "", "", "relative"],
    top: ["", "", "", "-10rem"],
    backgroundColor: "background",
    padding: ["0rem 1rem", "", "", "2rem 5rem", "3rem 6rem"],
    position: "relative",
  },

  column2: {
    padding: "1rem 1rem 1rem 0rem",
    width: ["100%", "", "", "25%"],
  },

  articleInfoMobile: {
    display: ["flex", "", "", "none"],
    flexDirection: "column",
  },
  articleInfoDesktop: {
    display: ["none", "", "", "flex"],
    flexDirection: "column",
  },

  timeContainer: {
    display: "flex",
    flexWrap: "wrap",
    borderBottom: "1px solid",
    opacity: "0.6",
    padding: "0.5rem 0rem 0rem",
    margin: "auto 0rem 2rem",
    width: "100%",
  },

  readingTime: {
    fontSize: "0.8em",
    margin: "0rem 1rem 0rem 0rem",
    padding: "0.5rem 0rem",
  },

  createdAt: {
    fontSize: "0.8em",
    margin: "0rem 1rem 0rem 0rem",
    padding: "0.5rem 0rem",
  },

  imageContainer: {
    marginBottom: "1rem",
    width: "calc(100% + 2rem)",
    position: "relative",
    left: "-1rem",
    maxHeight: "75vh",
  },

  image: {
    width: "100%",
    maxHeight: "75vh",
    filter: "brightness(0.85)",
  },

  bodyContainer: {
    h1: {
      fontSize: "2rem",
      margin: "0rem 0rem 1rem",
    },
    h2: {
      fontSize: "1.75rem",
      margin: "0rem 0rem 1rem",
    },
    h3: {
      fontSize: "1.5rem",
      margin: "0rem 0rem 1rem",
    },
    h4: {
      fontSize: "1.25rem",
      margin: "0rem 0rem 1rem",
    },
    h5: {
      fontSize: "1rem",
      margin: "0rem 0rem 1rem",
    },
    h6: {
      fontSize: "0.8rem",
      margin: "0rem 0rem 1rem",
    },
    p: {
      lineHeight: "1.5",
      margin: "0rem 0rem 1rem",
      fontWeight: "normal",
    },
    a: {
      fontWeight: "bold",
    },
    ul: {
      margin: "0rem 0rem 1rem",
    },
    ol: {
      margin: "0rem 0rem 1rem",
    },
    li: {
      lineHeight: "1.5",
      marginBottom: "0.5rem",
    },
  },

  ctaButton: {
    margin: ["1rem 0rem", "", "", "2rem 2rem"],
    position: ["", "", "", "absolute"],
    top: "0rem",
    right: "0rem",
    backgroundColor: "primary",
    color: "white",
    padding: "0.75rem 1rem",
    borderRadius: "10px",
  },

  // ? =====================
  // ? ======= Tags ========
  // ? =====================

  tagsContainer: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    marginBottom: "2rem",
  },
  tag: {
    margin: "0.5rem 0.5rem 0.5rem 0rem",
    backgroundColor: "#37393a",
    color: "white",
    borderRadius: "100px",
    padding: "0.25rem 0.75rem",
    fontSize: "0.8rem",
  },

  // ? ===============================
  // ? ======= Other Articles ========
  // ? ===============================

  otherArticleHeading: {
    color: "#ca3625",
    fontSize: ["2rem", "", "1.25rem"],
    margin: ["1rem 0rem 0rem", "", "", "1rem 0rem"],
    width: "100%",
    borderTop: ["1px solid black", "", "", "none"],
    borderBottom: ["", "", "", "1px solid black"],
    padding: ["1rem", "", "", "0.5rem 0rem"],
  },
  otherArticles: {
    width: "100%",
    padding: ["1rem", "", "", "0rem"],
  },
  otherArticle: {
    display: "flex",
    width: "100%",
    marginBottom: "0.5rem",
    ":hover": {
      h5: {
        textDecoration: "underline",
      },
    },
  },
  otherArticleImage: {
    width: "30%",
  },
  otherArticleContent: {
    padding: "0.5rem",
    width: "70%",
    display: "flex",
    flexDirection: "column",
  },
  otherArticleTitle: {
    fontSize: "1rem",
    lineHeight: "1.25",

    color: "#00557c",
  },
  otherArticleSubtitle: {
    fontSize: "0.8rem",
  },
  otherArticleDate: {
    fontSize: "0.7rem",
    opacity: "0.5",
  },
}
