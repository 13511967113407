// ?===============================
// ?=====  3rd party Imports  =====
// ?===============================
import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { Box } from 'theme-ui'
// ?===================================
// ?===  Local Components Imports  ====
// ?===================================
import Layout from '../components/Layout'

// ?===================================
// ?========  Style Imports  ==========
// ?===================================

// import ArticleMenu from "../components/Articles/ArticleMenu"
import ArticlePage from '../components/Articles/ArticlePage'

// This Template is used on every generated page on the site. apart from the multi location Landing page.
// its job is to get the data from the page context and graphql query and then funnel it to the right locations.
// data has to passed into two places. The Layout(i.e navigation and footer.) and the dyanmic component renderer
// this componenet also handle a dynamic theme color. passing the data into theme UI.

// ?===================================
// ?======  Template Component  =======
// ?===================================

const ArticlePageTemplate = (props) => {
  // ?==========================
  // ?====== Destructing  ======
  // ?==========================

  const { pageContext } = props

  // PAGE Context data that comes from gatsby node file gets desctructed from here
  const { articleID, pageObjectKey } = pageContext

  // ?==============================
  // ?=====  Variable Creation  ====
  // ?==============================

  // ?=============================================
  // ?==============   Functions   ================
  // ?=============================================

  const articleDataSelector = (pageObjectKey) => {
    switch (pageObjectKey) {
      case 'blog':
        return 'blog'

      case 'team':
        return 'team'

      case 'press':
        return 'press'

      case 'general':
        return 'general'

      case 'policies':
        return 'policies'

      default:
        break
    }
  }

  const allArticleDataSelector = (pageObjectKey) => {
    switch (pageObjectKey) {
      case 'blog':
        return 'allBlog'

      case 'team':
        return 'allTeam'

      case 'press':
        return 'allPress'

      case 'general':
        return 'allGeneral'

      case 'policies':
        return 'allPolicies'

      default:
        break
    }
  }

  // ?===================================================
  // ?==============   ON/OFF MOUNTING   ================
  // ?===================================================

  useEffect(() => {
    console.log(props)
    // console.log(props.data[articleDataSelector(pageObjectKey)])

    return () => {}
  }, [])

  // ?=========================
  // ?===== JSX rendering =====
  // ?=========================

  return (
    <Box sx={styles.container}>
      {props.data[articleDataSelector(pageObjectKey)] ? (
        <ArticlePage
          articleData={props.data[articleDataSelector(pageObjectKey)]}
          pageObjectKey={pageObjectKey}
          allArticlesData={props.data[
            allArticleDataSelector(pageObjectKey)
          ].edges.map(({ node }) => node)} // strip and destructure back the layers of objects(removing edges and node) and have just had one level of nested objects
        />
      ) : (
        'No Articles'
      )}
    </Box>
  )
}

export default ArticlePageTemplate

const styles = {
  container: {
    paddingTop: '62px',
    display: 'flex',
    flexDirection: 'column',
  },
}

// ?=====================================
// ?======= GraphQl page queries ========
// ?=====================================

export const query = graphql`
  query articlePageTemplateQuery($articleID: String!) {
    allBlog(sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          articleType
          createdAt
          id
          media {
            name
            description
            cloudinaryId
          }
          title
          subtitle
          readingTimeText
        }
      }
    }
    blog(id: { eq: $articleID }) {
      title
      subtitle
      readingTimeText
      readingTime
      media {
        cloudinaryId
        description
        name
      }
      id
      description
      createdAt
      bodyPlainText
      bodyHTML
      body
      tags
      author
      articleType
      stats {
        rants
        raves
        shares
        followers
      }
      ctaLink {
        title
        url
      }
    }
    allTeam {
      edges {
        node {
          id
          articleType
          title
          subtitle
          readingTimeText
          createdAt
          media {
            name
            description
            cloudinaryId
          }
        }
      }
    }
    team(id: { eq: $articleID }) {
      id
      body
      bodyHTML
      bodyPlainText
      createdAt
      description
      title
      subtitle
      readingTimeText
      readingTime
      media {
        cloudinaryId
        description
        name
      }
      ctaLink {
        title
        url
      }
    }
    allPress(sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          id
          articleType
          title
          subtitle
          readingTimeText
          createdAt
          media {
            name
            description
            cloudinaryId
          }
        }
      }
    }
    press(id: { eq: $articleID }) {
      id
      body
      bodyHTML
      bodyPlainText
      createdAt
      description
      title
      subtitle
      readingTimeText
      readingTime
      media {
        cloudinaryId
        description
        name
      }
      ctaLink {
        title
        url
      }
    }
    allPolicies(sort: { order: DESC, fields: createdAt }) {
      edges {
        node {
          id
          articleType
          title
          subtitle
          readingTimeText
          createdAt
          media {
            name
            description
            cloudinaryId
          }
        }
      }
    }
    policies(id: { eq: $articleID }) {
      id
      body
      bodyHTML
      bodyPlainText
      createdAt
      description
      title
      subtitle
      readingTimeText
      readingTime
      media {
        cloudinaryId
        description
        name
      }
      ctaLink {
        title
        url
      }
    }
    general(id: { eq: $articleID }) {
      id
      body
      bodyHTML
      bodyPlainText
      createdAt
      description
      title
      subtitle
      readingTimeText
      readingTime
      media {
        cloudinaryId
        description
        name
      }
      ctaLink {
        title
        url
      }
    }
  }
`
