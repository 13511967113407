export default {
  container: {
    maxWidth: '680px',
    margin: '0 auto',
    padding: ['62px 1rem 1rem'],
    width: '100%',
    '.slick-slider': {
      display: 'none',
    },
    '.otherArticlesContainer': {
      display: 'none',
    },
  },

  title: {
    fontSize: ['2.5rem', '2.75rem', '3rem'],
    marginBottom: '1rem',
  },
  subtitle: {
    marginBottom: '1rem',
  },
  text: {},

  timeContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    borderBottom: '1px solid',
    opacity: '0.6',
    padding: '0.5rem 0rem 0rem',
    margin: 'auto 0rem 2rem',
    width: '100%',
  },

  readingTime: {
    fontSize: '0.8em',
    margin: '0rem 1rem 0rem 0rem',
    padding: '0.5rem 0rem',
    display: 'none',
  },

  createdAt: {
    fontSize: '0.8em',
    margin: '0rem 1rem 0rem 0rem',
    padding: '0.5rem 0rem',
  },

  imageContainer: {
    marginBottom: '1rem',
    width: '100%',
  },

  image: {
    width: '100%',
  },

  bodyContainer: {
    h1: {
      fontSize: '2rem',
      margin: '0rem 0rem 1rem',
    },
    h2: {
      fontSize: '1.75rem',
      margin: '0rem 0rem 1rem',
    },
    h3: {
      fontSize: '1.5rem',
      margin: '0rem 0rem 1rem',
    },
    h4: {
      fontSize: '1.25rem',
      margin: '0rem 0rem 1rem',
    },
    h5: {
      fontSize: '1rem',
      margin: '0rem 0rem 1rem',
    },
    h6: {
      fontSize: '0.8rem',
      margin: '0rem 0rem 1rem',
    },
    p: {
      lineHeight: '1.5',
      margin: '0rem 0rem 1rem',
    },
    ul: {
      margin: '0rem 0rem 1rem',
    },
    ol: {
      margin: '0rem 0rem 1rem',
    },
    li: {
      lineHeight: '1.5',
      marginBottom: '0.5rem',
    },
  },

  ctaButton: {},
}
